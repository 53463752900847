// extracted by mini-css-extract-plugin
export var background = "style-module--background--6597c";
export var backgroundImg = "style-module--background-img--3c591";
export var containerHowWorkBot = "style-module--container-how-work-bot--331d7";
export var containerServices = "style-module--container-services--b9d78";
export var containerServicesSecond = "style-module--container-services-second--d49bd";
export var containerTop = "style-module--container-top--09493";
export var description = "style-module--description--8bf2b";
export var faqContainer = "style-module--faq-container--2d962";
export var faqTitle = "style-module--faq-title--42c20";
export var fnsReminder = "style-module--fns-reminder--73f83";
export var fnsReminderContent = "style-module--fns-reminder-content--40126";
export var fnsReminderImg = "style-module--fns-reminder-img--1a5d6";
export var fnsReminderImgContainer = "style-module--fns-reminder-img-container--12cb5";
export var howReceiveMoney = "style-module--how-receive-money--6a8d7";
export var receiveDescription = "style-module--receive-description--a2e2c";
export var receiveMoneyLinks = "style-module--receive-money-links--ffbfb";
export var receiveMoneyLinksItem = "style-module--receive-money-links-item--8e4f8";
export var receiveMoneyLinksItemButton = "style-module--receive-money-links-item-button--2a21a";
export var receiveMoneyLinksItemDescription = "style-module--receive-money-links-item-description--6b498";
export var receiveMoneyLinksItemImg = "style-module--receive-money-links-item-img--d3e7b";
export var receiveMoneyLinksItemText = "style-module--receive-money-links-item-text--754d9";
export var receiveMoneyLinksItemTitle = "style-module--receive-money-links-item-title--5f1a8";
export var receiveMoneyLinksItems = "style-module--receive-money-links-items--167a9";
export var receiveMoneyLinksTitle = "style-module--receive-money-links-title--f2883";
export var receiveTitle = "style-module--receive-title--79e89";
export var serviceBottom = "style-module--service-bottom--f3708";
export var serviceDescription = "style-module--service-description--2ae92";
export var serviceItem = "style-module--service-item--47ffb";
export var serviceItemContainerImg = "style-module--service-item-container-img--de195";
export var serviceItemDescription = "style-module--service-item-description--b7d77";
export var serviceItemImg = "style-module--service-item-img--fa926";
export var serviceItemLink = "style-module--service-item-link--71c50";
export var serviceItemTitle = "style-module--service-item-title--f360e";
export var serviceItems = "style-module--service-items--584e7";
export var serviceTitle = "style-module--service-title--0b4ec";
export var simpleAdvantages = "style-module--simple-advantages--d18f2";
export var simpleAdvantagesBottom = "style-module--simple-advantages-bottom--b3f53";
export var simpleAdvantagesBottomButton = "style-module--simple-advantages-bottom-button--036e5";
export var simpleAdvantagesBottomText = "style-module--simple-advantages-bottom-text--14d8d";
export var simpleAdvantagesCard = "style-module--simple-advantages-card--8faca";
export var simpleAdvantagesCardImage = "style-module--simple-advantages-card-image--51be3";
export var simpleAdvantagesCardTitle = "style-module--simple-advantages-card-title--d4831";
export var simpleAdvantagesCards = "style-module--simple-advantages-cards--d673b";
export var simpleAdvantagesTitle = "style-module--simple-advantages-title--0c0bc";
export var text = "style-module--text--b2da7";
export var title = "style-module--title--a5b0a";
export var tryButton = "style-module--try-button--ff576";
export var workBotBottom = "style-module--work-bot-bottom--5f575";
export var workBotItem = "style-module--work-bot-item--61ca6";
export var workBotItemDescription = "style-module--work-bot-item-description--b8650";
export var workBotItemImg = "style-module--work-bot-item-img--4e2d8";
export var workBotItemText = "style-module--work-bot-item-text--0d084";
export var workBotItemTitle = "style-module--work-bot-item-title--53198";
export var workBotItems = "style-module--work-bot-items--8052a";
export var workBotSteps = "style-module--work-bot-steps--056f1";