import React from "react";
import {Helmet} from "react-helmet";
import * as styles from "./style.module.scss";
import {StaticImage} from "gatsby-plugin-image";

import {ButtonUI, TypographyUI} from "@ui";
import {LAPTOP_MOBILE_POINTER, walletLink} from "@tools/constants";
import {BackgroundPosterCt, ButtonOfScrollCt, Layout} from "@components";

import sSelfworkerImg from "@images/telegram/service-selfworker.png";
import sIndividualImg from "@images/telegram/service-individual.png";
import fnsReminderImg from "@images/telegram/fns-reminder.png";
import sWalletImg from "@images/telegram/service-wallet.png";
import sLinkImg from "@images/telegram/service-link.png";

import simpleConversionImg from "@images/telegram/simple-conversion.png";
import simplePayImg from "@images/telegram/simple-pay.png";
import simpleFnsImg from "@images/telegram/simple-fns.png";
import simpleAutoImg from "@images/telegram/simple-auto.png";

import {useWindowSize} from "@tools/hooks";
import {ReceiveMoneyTg} from "@templates/telegram/receive-money-tg";
import {FaqTg} from "@templates/telegram/faq-tg";
import howWorkTgStepsImg from "@images/telegram/how-work-tg-steps.svg";

const TelegramPage = () => {

    const {width} = useWindowSize();
    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>
                    Получение оплаты в Телеграм самозанятыми | Платежи через Телеграм для самозанятых
                </title>
                <meta
                    name="description"
                    content="Нужно получение оплаты в Телеграм самозанятыми? Наш сайт поможет вам с этим. Прием платежей в Telegram с кошельком самозанятых. Принимайте оплату от клиентов прямо в чате Телеграм"
                />
                <link
                    rel="canonical"
                    href="https://xn--80aapgyievp4gwb.xn--p1ai/telegram"
                />
            </Helmet>
            <Layout>
                <div className={styles.containerTop}>
                    <BackgroundPosterCt/>
                    <div className={styles.text}>
                        <TypographyUI.H1
                            swichPoint={LAPTOP_MOBILE_POINTER}
                            className={styles.title}
                            variant="h1"
                        >Настройте прием платежей в Телеграм
                        </TypographyUI.H1>

                        <TypographyUI.Body1Regular
                            swichPoint={LAPTOP_MOBILE_POINTER}
                            className={styles.description}
                        >
                            Самозанятым неудобно получать деньги в мессенджерах и социальных сетях — нужно отправить
                            клиенту реквизиты счета, запросить платежные документы и вручную создать чек. Упростить
                            процесс можно с помощью Кошелька — нашего платежного сервиса
                        </TypographyUI.Body1Regular>

                        <a target="_blank" href={walletLink}>
                            <ButtonUI $w="240px" className={styles.tryButton} onClick={() => {}}>Попробовать</ButtonUI>
                        </a>
                    </div>

                    <div className={styles.background}>
                        <StaticImage
                            className={styles.backgroundImg}
                            src="../../images/telegram/background.png"
                            loading="eager"
                            placeholder="none"
                            formats={["auto", "webp"]}
                            width={620}
                            height={650}
                            layout="fixed"
                            objectFit="contain"
                            alt="background-img"
                        />
                    </div>
                </div>

                <ButtonOfScrollCt id="serviceFor" $w="100%" $mt="120px"/>

                <div id="serviceFor" className={styles.containerServices}>
                    <TypographyUI.H2
                        swichPoint={LAPTOP_MOBILE_POINTER}
                        className={styles.serviceTitle}
                    >
                        Кому подойдет прием платежей в Телеграм
                    </TypographyUI.H2>
                    <TypographyUI.Body1Regular
                        swichPoint={LAPTOP_MOBILE_POINTER}
                        className={styles.serviceDescription}
                    >
                        Мы создали сервис для физических лиц, которые хотят упростить прием денежных средств в интернете
                    </TypographyUI.Body1Regular>
                    <div className={styles.serviceItems}>
                        <div className={styles.serviceItem}>
                            <div className={styles.serviceItemContainerImg}>
                                <img
                                    className={styles.serviceItemImg}
                                    alt="service-selfworker"
                                    src={sSelfworkerImg}
                                />
                            </div>
                            <div>
                                <TypographyUI.H4Bold className={styles.serviceItemTitle}>
                                    Самозанятым для
                                </TypographyUI.H4Bold>
                                <TypographyUI.Body1Regular
                                    className={styles.serviceItemDescription}
                                >
                                    Регулярной продажи товаров собственного производства или услуг
                                </TypographyUI.Body1Regular>
                            </div>
                        </div>

                        <div className={styles.serviceItem}>
                            <div className={styles.serviceItemContainerImg}>
                                <img
                                    className={styles.serviceItemImg}
                                    alt="service-individual"
                                    src={sIndividualImg}
                                />
                            </div>
                            <div>
                                <TypographyUI.H4Bold className={styles.serviceItemTitle}>
                                    Физлицам без статуса для
                                </TypographyUI.H4Bold>
                                <TypographyUI.Body1Regular
                                    className={styles.serviceItemDescription}
                                >
                                    Донатов и разовых переводов от родственников и знакомых
                                </TypographyUI.Body1Regular>
                            </div>
                        </div>
                    </div>
                </div>


                <div className={styles.howReceiveMoney}>
                    <TypographyUI.H2
                        swichPoint={LAPTOP_MOBILE_POINTER}
                        className={styles.receiveTitle}
                    >
                        Как легко получать деньги через Телеграм и социальные сети
                    </TypographyUI.H2>
                    <TypographyUI.Body1Regular
                        className={styles.receiveDescription}
                    >
                        В Кошельке есть несколько способов для приема денег. Выберите тот, который больше всего подходит для ваших целей
                    </TypographyUI.Body1Regular>

                </div>

                <ReceiveMoneyTg/>


                <div className={styles.fnsReminder}>
                    <TypographyUI.H4Bold className={styles.fnsReminderContent}>Не важно, какой способ вы выбрали — если вы самозанятый, то после оплаты Кошелек самостоятельно сформирует чек, отправит информацию о продаже ФНС и напомнит, когда заплатить налоги</TypographyUI.H4Bold>
                    <div className={styles.fnsReminderImgContainer}>
                        <img className={styles.fnsReminderImg}
                            alt="fns-reminder"
                            src={fnsReminderImg}
                        />
                    </div>
                </div>

                <div className={styles.receiveMoneyLinks}>
                    <TypographyUI.H2
                        swichPoint={LAPTOP_MOBILE_POINTER}
                        className={styles.receiveMoneyLinksTitle}
                    >
                        Принимайте деньги по ссылке
                    </TypographyUI.H2>

                    <div className={styles.receiveMoneyLinksItems}>
                        <div className={styles.receiveMoneyLinksItem}>
                            <div className={styles.receiveMoneyLinksItemImg}>
                                <StaticImage
                                    src="../../images/telegram/receive-money-item.png"
                                    loading="eager"
                                    placeholder="none"
                                    layout="constrained"
                                    objectFit="contain"
                                    alt="receive-money-item"
                                />
                            </div>
                            <div className={styles.receiveMoneyLinksItemText}>
                                <TypographyUI.H3 className={styles.receiveMoneyLinksItemTitle}>
                                    Принимайте деньги по ссылке
                                </TypographyUI.H3>
                                <TypographyUI.Body1Regular
                                    className={styles.receiveMoneyLinksItemDescription}
                                >
                                    С помощью Кошелька вы создаёте универсальную или уникальную ссылку для оплаты, которую можно отправить любым удобным способом — в мессенджер, социальные сети, по имейлу или СМС<br/><br/>
                                    По ссылке ваш получатель попадает на платежную форму, в которой указана сумма к оплате. Если вы самозанятый, то после оплаты клиент получит чек, а вы — деньги
                                </TypographyUI.Body1Regular>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={[styles.containerServices, styles.containerServicesSecond].join(' ')}>
                    <TypographyUI.H2
                        swichPoint={LAPTOP_MOBILE_POINTER}
                        className={styles.serviceTitle}
                    >
                        Как начать работу с платежными ссылками
                    </TypographyUI.H2>
                    <div className={styles.serviceItems}>
                        <div className={styles.serviceItem}>
                            <div className={styles.serviceItemContainerImg}>
                                <img
                                    className={styles.serviceItemImg}
                                    alt="service-wallet"
                                    src={sWalletImg}
                                />
                            </div>
                            <div>
                                <TypographyUI.Body1Regular
                                    className={styles.serviceItemDescription}
                                >
                                    Зарегистрируйтесь в платежном сервисе Кошелек для физических лиц и самозанятых граждан
                                </TypographyUI.Body1Regular>
                            </div>
                        </div>

                        <div className={styles.serviceItem}>
                            <div className={styles.serviceItemContainerImg}>
                                <img
                                    className={styles.serviceItemImg}
                                    alt="service-link"
                                    src={sLinkImg}
                                />
                            </div>
                            <div>
                                <TypographyUI.Body1Regular
                                    className={styles.serviceItemDescription}
                                >
                                    В личном кабинете создайте универсальную или уникальную ссылку, отправьте ее получателю и дождитесь оплаты
                                </TypographyUI.Body1Regular>
                            </div>
                        </div>

                    </div>

                    <div className={styles.serviceBottom}>
                        {width && width > LAPTOP_MOBILE_POINTER && (
                            <a target="_blank" href={walletLink}>
                                <ButtonUI $w="324px" onClick={() => {
                                }}>Зарегистрироваться</ButtonUI>
                            </a>
                        )}
                        {width && width <= LAPTOP_MOBILE_POINTER && (
                            <a target="_blank" href={walletLink}>
                                <ButtonUI onClick={() => {
                                }}>Зарегистрироваться</ButtonUI>
                            </a>
                        )}
                    </div>

                </div>


                <div className={styles.containerHowWorkBot}>
                    <TypographyUI.H2
                        swichPoint={LAPTOP_MOBILE_POINTER}
                        className={styles.title}
                    >
                        Как работает Телеграм-бот для приема денег
                    </TypographyUI.H2>
                    <div className={styles.workBotSteps}>
                        <img src={howWorkTgStepsImg} alt="" />
                    </div>
                    <div className={styles.workBotItems}>
                        <div className={styles.workBotItem}>
                            <div className={styles.workBotItemImg}>
                                <StaticImage
                                    src="../../images/telegram/how-work-tg-bot-1.png"
                                    loading="eager"
                                    placeholder="none"
                                    layout="constrained"
                                    objectFit="contain"
                                    alt="how-work-tg-bot-1"
                                />
                            </div>

                            <div className={styles.workBotItemText}>
                                <TypographyUI.H4Bold className={styles.workBotItemTitle}>
                                    Шаг 1
                                </TypographyUI.H4Bold>
                                <TypographyUI.Body1Regular
                                    className={styles.workBotItemDescription}
                                >
                                    Покупатель заходит в ваш Телеграм-канал с настроенным чат-ботом и отправляет сообщение с заданной командой, например «Хочу оплатить консультацию»
                                </TypographyUI.Body1Regular>
                            </div>
                        </div>

                        <div className={styles.workBotItem}>
                            <div className={styles.workBotItemImg}>
                                <StaticImage
                                    src="../../images/telegram/how-work-tg-bot-2.png"
                                    loading="eager"
                                    placeholder="none"
                                    layout="constrained"
                                    objectFit="contain"
                                    alt="how-work-tg-bot-2"
                                />
                            </div>
                            <div className={styles.workBotItemText}>
                                <TypographyUI.H4Bold className={styles.workBotItemTitle}>
                                    Шаг 2
                                </TypographyUI.H4Bold>
                                <TypographyUI.Body1Regular
                                    className={styles.workBotItemDescription}
                                >
                                    Бот отправляет покупателю счет с нужной суммой
                                </TypographyUI.Body1Regular>
                            </div>
                        </div>

                        <div className={styles.workBotItem}>
                            <div className={styles.workBotItemImg}>
                                <StaticImage
                                    src="../../images/telegram/how-work-tg-bot-3.png"
                                    loading="eager"
                                    placeholder="none"
                                    layout="constrained"
                                    objectFit="contain"
                                    alt="how-work-tg-bot-3"
                                />
                            </div>
                            <div className={styles.workBotItemText}>
                                <TypographyUI.H4Bold className={styles.workBotItemTitle}>
                                    Шаг 3
                                </TypographyUI.H4Bold>
                                <TypographyUI.Body1Regular
                                    className={styles.workBotItemDescription}
                                >
                                    Покупатель попадает на форму оплаты прямо в Телеграм — счет можно оплатить банковской картой или через систему быстрых платежей
                                </TypographyUI.Body1Regular>
                            </div>
                        </div>

                        <div className={styles.workBotItem}>
                            <div className={styles.workBotItemImg}>
                                <StaticImage
                                    src="../../images/telegram/how-work-tg-bot-4.png"
                                    loading="eager"
                                    placeholder="none"
                                    layout="constrained"
                                    objectFit="contain"
                                    alt="how-work-tg-bot-4"
                                />
                            </div>
                            <div className={styles.workBotItemText}>
                                <TypographyUI.H4Bold className={styles.workBotItemTitle}>
                                    Шаг 4
                                </TypographyUI.H4Bold>
                                <TypographyUI.Body1Regular
                                    className={styles.workBotItemDescription}
                                >
                                    Бот отправляет сообщение об успешной оплате и передает информацию о продаже в личный кабинет Кошелька
                                </TypographyUI.Body1Regular>
                            </div>
                        </div>
                    </div>

                    <div className={styles.workBotBottom}>
                        {width && width > LAPTOP_MOBILE_POINTER && (
                            <a target="_blank" href={walletLink}>
                                <ButtonUI $w="324px" onClick={() => {
                                }}>Настроить чат-бот</ButtonUI>
                            </a>
                        )}
                        {width && width <= LAPTOP_MOBILE_POINTER && (
                            <a target="_blank" href={walletLink}>
                                <ButtonUI onClick={() => {
                                }}>Настроить чат-бот</ButtonUI>
                            </a>
                        )}
                    </div>

                </div>


                <div className={styles.simpleAdvantages}>
                    <TypographyUI.H2
                        swichPoint={LAPTOP_MOBILE_POINTER}
                        className={styles.simpleAdvantagesTitle}
                    >
                        Удобно для клиента, просто для вас
                    </TypographyUI.H2>

                    <div className={styles.simpleAdvantagesCards}>
                        <div className={styles.simpleAdvantagesCard}>
                            <img
                                className={styles.simpleAdvantagesCardImage}
                                alt="simple-conversion"
                                src={simpleConversionImg}
                            />
                            <div>
                                <TypographyUI.Body1Medium className={styles.simpleAdvantagesCardTitle}>Выше конверсии</TypographyUI.Body1Medium>
                                <TypographyUI.Body2Regular>
                                    Клиенту не придется вручную вводить ваши реквизиты — ему достаточно нажать кнопку и сразу оплатить товар или услугу
                                </TypographyUI.Body2Regular>
                            </div>
                        </div>
                        <div className={styles.simpleAdvantagesCard}>
                            <img
                                className={styles.simpleAdvantagesCardImage}
                                alt="simple-pay"
                                src={simplePayImg}
                            />
                            <div>
                                <TypographyUI.Body1Medium className={styles.simpleAdvantagesCardTitle}>Удобная оплата</TypographyUI.Body1Medium>
                                <TypographyUI.Body2Regular>
                                    Покупатели могут оплачивать товары и услуги с помощью банковской карты или через систему быстрых платежей — СБП
                                </TypographyUI.Body2Regular>
                            </div>
                        </div>
                        <div className={styles.simpleAdvantagesCard}>
                            <img
                                className={styles.simpleAdvantagesCardImage}
                                alt="simple-fns"
                                src={simpleFnsImg}
                            />
                            <div>
                                <TypographyUI.Body1Medium className={styles.simpleAdvantagesCardTitle}>Экономия времени</TypographyUI.Body1Medium>
                                <TypographyUI.Body2Regular>
                                    Сервис создаст платежную форму, отправит счет, примет деньги, выдаст чек и передаст информацию в «Мой налог» за вас
                                </TypographyUI.Body2Regular>
                            </div>
                        </div>
                        <div className={styles.simpleAdvantagesCard}>
                            <img
                                className={styles.simpleAdvantagesCardImage}
                                alt="simple-auto"
                                src={simpleAutoImg}
                            />
                            <div>
                                <TypographyUI.Body1Medium className={styles.simpleAdvantagesCardTitle}>Работа без сайта</TypographyUI.Body1Medium>
                                <TypographyUI.Body2Regular>
                                    Вы можете полностью автоматизировать прием платежей — ваш Телеграм-чат будет работать как полноценный онлайн-магазин
                                </TypographyUI.Body2Regular>
                            </div>
                        </div>
                    </div>

                    {width && width > LAPTOP_MOBILE_POINTER && (
                        <div className={styles.simpleAdvantagesBottom}>
                            <TypographyUI.H4Bold
                                className={styles.simpleAdvantagesBottomText}
                            >Мы не берем комиссию за прием платежей, только 3,5% на вывод средств через СБП или на банковскую карту</TypographyUI.H4Bold>
                            <a target="_blank" href={walletLink}>
                                <ButtonUI $w="324px" className={styles.simpleAdvantagesBottomButton} onClick={() => {
                                }}>Настроить оплату</ButtonUI>
                            </a>
                        </div>
                    )}
                </div>

                <div className={styles.faqContainer}>

                    <TypographyUI.H2
                        swichPoint={LAPTOP_MOBILE_POINTER}
                        className={styles.faqTitle}
                    >
                        Вопросы и ответы
                    </TypographyUI.H2>

                    <FaqTg/>
                </div>

            </Layout>
        </>
    )
};

export default TelegramPage;
