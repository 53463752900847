import React from "react";
import styled from "styled-components";
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Autoplay, Pagination} from "swiper";
import {EffectFade} from 'swiper';
import "swiper/css";
import "swiper/css/pagination";
import 'swiper/css/effect-fade';

import {LAPTOP_MOBILE_POINTER} from "@tools/constants";
import {TypographyUI} from "@ui";
import {useWindowSize} from "@tools/hooks";


export const ReceiveMoneyTg = () => {
    SwiperCore.use([Autoplay, Pagination]);
    const {width} = useWindowSize();

    const pagination = {
        clickable: true,
        renderBullet: (id, className) =>
            `<div class="custome-pagination ${className}"></div>`,
    };

    return (
        <>
            {width > LAPTOP_MOBILE_POINTER && (

                <SectionBoxSd>

                    <TitleSlidesSd>
                        <TypographyUI.H4Bold style={{color: 'var(--accent-main)'}}>Универсальная
                            ссылка</TypographyUI.H4Bold>
                        <TypographyUI.H4Bold style={{color: 'var(--accent-main)'}}>Уникальная
                            ссылка</TypographyUI.H4Bold>
                        <TypographyUI.H4Bold style={{color: 'var(--accent-main)'}}>Автоматическая
                            оплата</TypographyUI.H4Bold>
                    </TitleSlidesSd>


                    <div>
                        <SwiperSd
                            //modules={[EffectFade]} effect="fade"
                            slidesPerView={"auto"}
                            centeredSlides={true}
                            autoplay={{
                                delay: 10000,
                                disableOnInteraction: true,
                            }}
                            spaceBetween={40}
                            initialSlide={0}
                            pagination={pagination}
                            fadeEffect={{crossFade: true}}
                            loop={true}
                        >
                            <SwiperSlide>
                                <BoxOfContentSlideSd>
                                    <CardSd>
                                        <SlideContainerSd>
                                            <ItemSd style={{padding: "0px 30px"}}>
                                                Вы создаете одну или несколько универсальных ссылок, в которых указана
                                                фиксированная сумма оплаты
                                            </ItemSd>
                                            <DividerSd></DividerSd>
                                            <ItemSd style={{padding: "0px 30px"}}>
                                                Каждый раз вы генерируете уникальную ссылку с разными суммами и
                                                назначениями платежа
                                            </ItemSd>
                                            <DividerSd></DividerSd>
                                            <ItemSd style={{padding: "0px 30px"}}>
                                                Вы можете настроить Телеграм-бот, который самостоятельно генерирует и
                                                отправляет платежную форму
                                            </ItemSd>
                                        </SlideContainerSd>
                                    </CardSd>
                                </BoxOfContentSlideSd>
                            </SwiperSlide>
                            <SwiperSlide>
                                <BoxOfContentSlideSd>
                                    <CardSd>
                                        <SlideContainerSd>
                                            <ItemSd style={{padding: "0px 51px"}}>
                                                Самозанятым, которые предлагают небольшой ассортимент товаров или услуг
                                            </ItemSd>
                                            <DividerSd></DividerSd>
                                            <ItemSd style={{padding: "0px 51px"}}>
                                                <ItemDescSd>Кому подойдет</ItemDescSd>
                                                Самозанятым с плавающим ценником, физлицам для простых переводов
                                            </ItemSd>
                                            <DividerSd></DividerSd>
                                            <ItemSd style={{padding: "0px 44px"}}>
                                                Самозанятым, которые не успевают обрабатывать заявки клиентов и получают
                                                много заказов
                                            </ItemSd>
                                        </SlideContainerSd>
                                    </CardSd>
                                </BoxOfContentSlideSd>
                            </SwiperSlide>
                            <SwiperSlide>
                                <BoxOfContentSlideSd>
                                    <CardSd>
                                        <SlideContainerSd>
                                            <ItemSd style={{padding: "0px 51px"}}>
                                                Не нужно создавать отдельную ссылку для каждой продажи
                                            </ItemSd>
                                            <DividerSd></DividerSd>
                                            <ItemSd style={{padding: "0px 51px"}}>
                                                <ItemDescSd>Преимущество</ItemDescSd>
                                                Кто угодно может отправить вам деньги для разных целей
                                            </ItemSd>
                                            <DividerSd></DividerSd>
                                            <ItemSd style={{padding: "0px 50px"}}>
                                                Достаточно один раз настроить бот и больше не создавать ссылки вручную
                                            </ItemSd>
                                        </SlideContainerSd>
                                    </CardSd>
                                </BoxOfContentSlideSd>
                            </SwiperSlide>
                        </SwiperSd>
                    </div>
                </SectionBoxSd>
            )}


            {width <= LAPTOP_MOBILE_POINTER && (

                <SectionBoxSd>

                    <div>
                        <SwiperSd
                            //modules={[EffectFade]} effect="fade"
                            slidesPerView={"auto"}
                            centeredSlides={true}
                            autoplay={{
                                delay: 10000,
                                disableOnInteraction: true,
                            }}
                            spaceBetween={40}
                            initialSlide={0}
                            pagination={pagination}
                            loop={true}
                            fadeEffect={{crossFade: true}}
                        >
                            <SwiperSlide>
                                <BoxOfContentSlideSd>
                                        <TitleSlidesSd>
                                            <TypographyUI.H4Bold style={{color: 'var(--accent-main)'}}>Универсальная
                                                ссылка</TypographyUI.H4Bold>
                                        </TitleSlidesSd>
                                        <SlideContainerSd>
                                            <CardSd>
                                                <ItemSd>
                                                    Вы создаете одну или несколько универсальных ссылок, в которых указана
                                                    фиксированная сумма оплаты
                                                </ItemSd>
                                                <DividerSd></DividerSd>
                                                <ItemSd>
                                                    <ItemDescSd>Кому подойдет</ItemDescSd>
                                                    Самозанятым, которые предлагают небольшой ассортимент товаров или услуг
                                                </ItemSd>
                                                <DividerSd></DividerSd>
                                                <ItemSd>
                                                    <ItemDescSd>Преимущество</ItemDescSd>
                                                    Не нужно создавать отдельную ссылку для каждой продажи
                                                </ItemSd>
                                            </CardSd>
                                        </SlideContainerSd>
                                </BoxOfContentSlideSd>
                            </SwiperSlide>

                            <SwiperSlide>
                                <BoxOfContentSlideSd>
                                    <TitleSlidesSd>
                                        <TypographyUI.H4Bold style={{color: 'var(--accent-main)'}}>Уникальная ссылка</TypographyUI.H4Bold>
                                    </TitleSlidesSd>
                                    <SlideContainerSd>
                                        <CardSd>
                                            <ItemSd>
                                                Каждый раз вы генерируете уникальную ссылку с разными суммами и назначениями платежа
                                            </ItemSd>
                                            <DividerSd></DividerSd>
                                            <ItemSd>
                                                <ItemDescSd>Кому подойдет</ItemDescSd>
                                                Самозанятым с плавающим ценником, физлицам для простых переводов
                                            </ItemSd>
                                            <DividerSd></DividerSd>
                                            <ItemSd>
                                                <ItemDescSd>Преимущество</ItemDescSd>
                                                Кто угодно может отправить вам деньги для разных целей
                                            </ItemSd>
                                        </CardSd>
                                    </SlideContainerSd>
                                </BoxOfContentSlideSd>
                            </SwiperSlide>

                            <SwiperSlide>
                                <BoxOfContentSlideSd>
                                    <TitleSlidesSd>
                                        <TypographyUI.H4Bold style={{color: 'var(--accent-main)'}}>Автоматическая оплата</TypographyUI.H4Bold>
                                    </TitleSlidesSd>
                                    <SlideContainerSd>
                                        <CardSd>
                                            <ItemSd>
                                                Вы можете настроить Телеграм-бот, который самостоятельно генерирует и отправляет платежную форму
                                            </ItemSd>
                                            <DividerSd></DividerSd>
                                            <ItemSd>
                                                <ItemDescSd>Кому подойдет</ItemDescSd>
                                                Самозанятым, которые не успевают обрабатывать заявки клиентов и получают много заказов
                                            </ItemSd>
                                            <DividerSd></DividerSd>
                                            <ItemSd>
                                                <ItemDescSd>Преимущество</ItemDescSd>
                                                Достаточно один раз настроить бот и больше не создавать ссылки вручную
                                            </ItemSd>
                                        </CardSd>
                                    </SlideContainerSd>
                                </BoxOfContentSlideSd>
                            </SwiperSlide>
                        </SwiperSd>
                    </div>
                </SectionBoxSd>
            )}
        </>
    );
};

const SectionBoxSd = styled.section`
  display: flex;
  flex-direction: column;
  transition: all 0.3s;
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    padding-bottom: 60px;
  }
`;


const TitleSlidesSd = styled.div`
  width: 1155px;
  padding: 0 35px;
  display: flex;
  justify-content: space-around;
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    width: 100%;
    justify-content: center;
    padding: 0 0 12px;
  }
`;

const SwiperSd = styled(Swiper)`
  width: 100%;
  height: 100%;

  .swiper-slide {
    width: 1155px;
    margin-bottom: 52px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
      width: 100%;
    }
  }

  .swiper-pagination {
    padding-top: 24px;
    bottom: 50px !important;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
      bottom: 20px !important;
    }

    .custome-pagination {
      background: #b0b5c4;
      border-radius: 5px;
      height: 3px;
      width: 22px;

      & + & {
        margin-left: 20px;
      }
    }
  }
`;
const BoxOfContentSlideSd = styled.div`
  padding-top: 16px;
`;

const CardSd = styled.div`
  background: #ffffff;
  border-radius: 30px;
  width: 1155px;
  padding: 35px 35px;
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    width: 100%;
    padding: 0;
  }
`;

const SlideContainerSd = styled.div`
  box-shadow: 0px 8px 24px 0px rgba(97, 106, 136, 0.08);
  display: flex;
  align-items: center;
  height: 235px;
  border-radius: 32px;
  position: relative;
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    height: 100%;
    margin: 16px 16px;
    align-items: self-start;
  }
`;

const ItemSd = styled.div`
  padding: 0px 30px;
  text-align: center;
  color: var(--text-secondary);
  width: 370px;
  font-family: IBMPlexSans, Tahoma, Geneva, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    width: 100%;
    padding: 0 12px 16px;
    min-height: 140px;
    
    &:first-child {
      padding-top: 20px;
      min-height: 110px;
    }
    &:last-child {
      padding-bottom: 20px;
    }
  }
`;

const ItemDescSd = styled.div`
  border-radius: 0px 0px 6px 6px;
  background: var(--light-shade, #E9EFF6);
  position: absolute;
  top: 0;
  margin: 0 auto;
  width: 228px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: var(--text-placeholder);
  left: calc(50% - (228px / 2));
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    left: 0;
    position: relative;
    margin-bottom: 20px;
  }
`;

const DividerSd = styled.div`
  opacity: 0.1;
  background: var(--text-secondary);
  display: flex;
  height: 185px;
  flex-direction: column;
  align-items: flex-start;
  width: 1px;
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    width: 100%;
    height: 1px;
  }
`;
